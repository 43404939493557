@import 'shared/styles/abstract';

.detail {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 auto;
  text-align: center;
}

.top {
  display: flex;
  margin: 0 0 $spacing-s;
  align-items: center;
  justify-content: center;
}

.pic {
  display: block;
  margin-top: 4px;
  margin-right: $spacing-s;
  width: 20px;
}

.name {
  @include title;

  flex: 0 1 auto;
  margin: 0;
  padding: 0;
}

.pictures {
  display: flex;
  justify-content: center;
  margin-top: $spacing;
}

.gender {
  --size-icon: 18px;
  display: inline-block;
  margin-right: $spacing-s;
}
