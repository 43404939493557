@import 'shared/styles/abstract.scss';

.wrapper {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @media (min-width: $media-m) {
    flex-direction: row;
  }
}

.svg {
  @include themed;

  display: block;
  flex: 1 1 auto;
  background-color: var(--color-bg);
  will-change: flex-basis;
}

.menu {
  @media (min-width: $media-m) {
    position: absolute;
    bottom: $spacing;
    left: $spacing;
    z-index: 2;
  }
}

// SVG styles
:global {
  .actions {
    @include themed;

    fill: var(--color-node-actions);
  }

  // The root node is already displayed with the parent tree
  .node.similars.root,
  .node.children.root {
    display: none;
  }

  .rect {
    @include themed;

    filter: drop-shadow(2px 2px 1px var(--color-shadow));
  }

  .link {
    @include themed;

    fill: none;
    stroke: var(--color-link);
    stroke-width: 1px;
  }
}
