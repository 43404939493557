@import 'shared/styles/abstract.scss';

.title {
  @include heading;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
