@import 'shared/styles/abstract.scss';

.wrapper {
  display: flex;
}

.relation {
  @include ellipsis;

  flex: 1 1 auto;
}

.date {
  flex: 0 0 auto;
}
