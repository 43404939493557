@import 'shared/styles/abstract.scss';

.modal {
  @include themed;

  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-bg);
}

.close {
  @include btn-over;

  position: absolute;
  top: $spacing-l;
  right: $spacing-l;
  z-index: 1;
}

.in {
  position: relative;
  width: 100%;
  height: 100%;
}
