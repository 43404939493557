@import 'shared/styles/abstract';

.icon {
  @include themed;

  display: block;
  stroke: var(--color-icon);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  width: var(--size-icon);
  height: var(--size-icon);
}
