@import 'shared/styles/abstract.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.menu {
  position: absolute;
  right: $spacing-l;
  bottom: $spacing-l;
  z-index: 1;
  display: flex;
}

.cta {
  @include btn-over;

  margin-right: $spacing;

  &:last-of-type {
    margin-right: 0;
  }
}
