@import 'shared/styles/abstract.scss';

.link {
  @include themed;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.name {
  @include ellipsis;

  flex: 1 1 auto;
}

.date {
  flex: 0 0 auto;
  margin-left: $spacing;
}
