:root {
  // globals
  --color-primary: hsl(180, 100%, 11%);
  --color-loader: hsl(180, 100%, 11%);
  --color-primary-light: hsl(180, 97%, 15%);
  --color-text: hsl(0, 0%, 0%);
  --color-text-light: hsl(0, 0%, 40%);
  --color-text-opposite: hsl(0, 0%, 100%);
  --color-bg: hsl(0, 0%, 96%);
  --color-bg-light: hsl(0, 0%, 100%);
  --color-bg-dark: hsl(0, 0%, 92%);
  --color-bg-opposite: hsl(180, 100%, 11%);
  --color-bg-opposite-alt: hsl(180, 97%, 15%);
  --color-icon: hsl(0, 0%, 0%);
  --color-shadow: hsl(0, 0%, 92%);

  // inputs
  --color-input-bg: var(--color-bg);
  --color-input-bg-hover: var(--color-primary-light);
  --color-input-text: var(--color-text);
  --color-input-text-hover: var(--color-text-opposite);

  // tree links
  --color-link: hsl(240, 20%, 83%);

  // tree nodes
  --color-node-actions: hsl(0deg 0% 100% / 30%);
  --color-node-button: hsl(0, 0%, 50%);

  --color-node-root-from: hsl(5, 100%, 81%);
  --color-node-root-to: hsl(5, 100%, 88%);
  --color-node-parent-from: hsl(162, 70%, 75%);
  --color-node-parent-to: hsl(162, 75%, 85%);
  --color-node-parentend-from: hsl(0, 0%, 85%);
  --color-node-parentend-to: hsl(0, 0%, 90%);
  --color-node-child-from: hsl(162, 70%, 75%);
  --color-node-child-to: hsl(162, 75%, 85%);
  --color-node-sibling-from: hsl(0, 0%, 85%);
  --color-node-sibling-to: hsl(0, 0%, 89%);
  --color-node-union-from: hsl(32, 96%, 82%);
  --color-node-union-to: hsl(32, 94%, 88%);

  // tree person nodes
  --color-node-person-light: var(--color-text-light);

  // sizes
  --size-icon: 20px;

  // notifs
  --color-notif-text: hsl(0, 0%, 100%);

  // result
  --color-result-bg: var(--color-bg);
  --color-result-text: var(--color-text);
  --color-result-bg-hover: var(--color-primary-light);
  --color-result-text-hover: var(--color-text-opposite);

  // loader
  --color-loader-bg: var(--color-bg);
}

[data-theme='dark'] {
  --color-primary: hsl(180, 100%, 11%);
  --color-primary-light: hsl(180, 97%, 23%);
  --color-loader: hsl(0, 0%, 100%);
  --color-text: hsl(0, 0%, 100%);
  --color-text-light: hsl(0, 0%, 50%);
  --color-text-opposite: hsl(0, 0%, 0%);
  --color-bg: hsl(228, 5%, 22%);
  --color-bg-light: hsl(225, 6%, 13%);
  --color-bg-dark: hsl(228, 3%, 28%);
  --color-bg-opposite: hsl(0, 0%, 100%);
  --color-bg-opposite-alt: hsl(0, 0%, 80%);
  --color-icon: hsl(0, 0%, 100%);
  --color-shadow: rgba(15, 15, 15, 0.5);
  --color-input-bg-hover: var(--color-primary-light);
  --color-input-text-hover: var(--color-text);

  --color-node-root-from: hsl(1, 100%, 74%);
  --color-node-root-to: hsl(1, 100%, 78%);
  --color-node-parent-from: hsl(162, 73%, 70%);
  --color-node-parent-to: hsl(162, 82%, 75%);
  --color-node-child-from: hsl(162, 73%, 70%);
  --color-node-child-to: hsl(162, 82%, 75%);
  --color-node-union-from: hsl(32, 96%, 79%);
  --color-node-union-to: hsl(32, 94%, 83%);

  --color-link: hsl(240, 10%, 55%);
  --color-node-button: hsl(0, 0%, 30%);
  --color-node-person-light: hsl(0, 0%, 25%);

  --color-notif-text: hsl(0, 0%, 0%);

  --color-result-text-hover: var(--color-text);
}
