@import 'shared/styles/abstract.scss';

* {
  box-sizing: border-box;
}

html {
  @include themed;

  background-color: var(--color-bg-light);
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--color-text);
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: $font-size;
  line-height: 1.6;
}

#root,
.app {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
