@import 'shared/styles/abstract';

.wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.birth,
.death,
.work {
  ._ellipsis & {
    @include ellipsis;
  }
}

.birthdate,
.deathdate,
.birthage,
.deathage,
.birthplace,
.deathplace {
  display: inline;
}
