// Font sizes
$font-size-xl: 18px;
$font-size-l: 16px;
$font-size: 14px;
$font-size-s: 12px;

// Spacings
$spacing-xl: 32px;
$spacing-l: 24px;
$spacing: 16px;
$spacing-s: 8px;
$spacing-xs: 4px;

// Breakpoints
$media-xs: 480px;
$media-s: 768px;
$media-m: 992px;
$media-l: 1200px;
$media-xl: 1500px;

// Transitions
$transition-duration-xs: 160ms;
$transition-duration-s: 240ms;
$transition-duration: 320ms;
$transition-duration-l: 480ms;
$transition-duration-xl: 640ms;
$transition-duration-xxl: 1024ms;
$transition-effect: cubic-bezier(0.215, 0.61, 0.355, 1);

// Radius
$radius: 4px;
$radius-l: 8px;

// Mixins
@mixin title {
  font-size: $font-size-xl;
  font-weight: 800;
}

@mixin subtitle {
  font-size: $font-size-l;
  font-weight: 700;
}

@mixin heading {
  margin: 0 0 $spacing-s;
  font-size: $font-size-l;
  font-weight: 700;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin fade-off {
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration, visibility 0s $transition-duration;
}

@mixin fade-on {
  opacity: 1;
  visibility: visible;
  transition: opacity $transition-effect $transition-duration;
}

@mixin themed {
  transition: all $transition-effect $transition-duration;
}

@mixin btn-over {
  @include themed;

  --color-icon: var(--color-text-opposite);
  --size-icon: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: var(--color-bg-opposite);
  padding: 5px;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--color-bg-opposite-alt);
  }

  &:disabled {
    opacity: 0.5;
  }
}
