@import 'shared/styles/abstract';

.block {
  @include themed;

  background-color: var(--color-bg);
  padding: $spacing;
  border-radius: $radius-l;
  margin-top: $spacing-l;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
}
