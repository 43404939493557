@import 'shared/styles/abstract.scss';

$size: 80px;

.wrapper {
  margin-right: $spacing;
  width: $size;
  height: $size;
  cursor: pointer;
  overflow: hidden;

  &:last-of-type {
    margin-right: 0;
  }
}

.img {
  @include themed;

  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  padding: 3px;
  background-color: var(--color-bg);
}
