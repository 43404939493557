@import 'shared/styles/abstract.scss';

$button-padding: 12px;

@mixin menu-button {
  @include themed;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  border: 0;
  background-color: var(--color-bg-light);
  font-size: $font-size;
  margin: 0;
  padding: $button-padding;
  cursor: pointer;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background-color: var(--color-bg-dark);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.wrapper {
  @include themed;

  display: flex;
  justify-content: flex-end;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));

  @media (min-width: $media-m) {
    background-color: transparent;
    flex-direction: column;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;

  @media (min-width: $media-m) {
    flex-direction: column;
  }
}

.group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: $media-m) {
    flex-direction: column;

    &._reverse {
      flex-direction: column-reverse;
    }
  }
}

.cta {
  @include menu-button;

  z-index: 2;
}

.pic {
  display: block;
  height: 20px;
}

.subgroup {
  position: absolute;
  z-index: 1;
  display: flex;
  top: -45px;

  @media (min-width: $media-m) {
    flex-direction: column;
    top: 0;
    left: 45px;
  }
}

.subcta {
  @include menu-button;
}
