@import 'shared/styles/abstract.scss';

.notif {
  @include ellipsis;
  @include themed;

  border-radius: $radius;
  background-color: var(--color-bg-opposite);
  padding: 10px $spacing;
  height: 40px;
  color: var(--color-notif-text);
}
