@import 'shared/styles/abstract';

.wrapper {
  @include themed;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-loader-bg);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  @include themed;

  display: block;
  width: 38px;
  height: 38px;
  stroke: var(--color-loader);
  stroke-width: 2;
}
