@import 'shared/styles/abstract.scss';

.ref {
  text-decoration: none;
  cursor: pointer;
}

.link {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
