@import 'shared/styles/abstract.scss';

.title {
  @include heading;
}

.btn {
  @include themed;

  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  overflow: hidden;
  font-size: $font-size;
  color: var(--color-text);
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.icon {
  --size-icon: 14px;
  display: inline-block;
  margin-left: $spacing-s;
}

.id {
  display: flex;
  overflow: hidden;
}

.label {
  flex: 0 0 auto;
  margin-right: $spacing-xs;
}

.value {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}

.text {
  @include ellipsis;

  flex: 0 1 auto;
}
