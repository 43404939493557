@import 'shared/styles/abstract.scss';

$card-width: 400px;

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: hidden;
}

.top {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.logo {
  @include themed;

  fill: var(--color-primary-light);
  padding: 0 0 $spacing;
  width: 100%;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
}

.form {
  display: flex;
  justify-content: center;
  border-radius: $radius-l;
  width: 100%;
  overflow: hidden;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
}

.field {
  position: relative;
  flex: 1 1 auto;
}

.input {
  @include themed;

  width: 100%;
  background-color: var(--color-input-bg);
  margin: 0;
  border: 0;
  border-radius: 0;
  color: var(--color-text);
  padding: $spacing-s $spacing;
  height: 150px;
  height: 100%;
  outline: 0;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
  appearance: none;
  font-size: inherit;
  font-weight: inherit;

  &::placeholder {
    color: var(--color-text-light);
  }
}

.reset {
  position: absolute;
  appearance: none;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  top: 50%;
  right: $spacing;
  padding: $spacing-s;
  cursor: pointer;
  transition: all $transition-effect $transition-duration;
  outline: 0;

  &:hover,
  &:focus {
    background-color: var(--color-bg-light);
  }
}

.submit {
  @include themed;

  flex: 0 0 auto;
  appearance: none;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: var(--color-input-bg);
  padding: $spacing-s $spacing;
  height: 100%;
  outline: 0;
  color: var(--color-input-text);
  cursor: pointer;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
  font-size: inherit;
  font-weight: inherit;

  &:focus,
  &:hover {
    background-color: var(--color-input-bg-hover);
    color: var(--color-input-text-hover);
  }
}

.wrapper {
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 0 1 auto;
  overflow: hidden;
  overflow-y: auto;
}

.in {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  padding: $spacing;

  @media (min-width: $media-m) {
    padding: $spacing-xl;
  }
}

.list {
  position: relative;
  display: block;
}

.item {
  @include themed;

  width: 100%;
}

.total {
  margin-bottom: $spacing;
  text-align: center;
  color: var(--color-text-light);

  @media (min-width: $media-m) {
    margin-bottom: $spacing-xl;
  }
}

.more {
  text-align: center;
  color: var(--color-text-light);
}
