@import 'shared/styles/abstract.scss';

.notifier {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding: $spacing;
  max-width: 100%;
  overflow: hidden;
}

.item {
  max-width: 100%;
}
