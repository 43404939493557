@import 'shared/styles/abstract.scss';

$detail-width: 400px;

.page {
  @include themed;

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  background-color: var(--color-bg-light);
  overflow: hidden;
}

.in {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  z-index: 1;
  width: 100%;
  // we use a transition hack to resize tree when the detailed view is open
  transition: width;
  transition-duration: 0;
  transition-delay: 0;

  ._detailed & {
    @media (min-width: $media-m) {
      width: calc(100% - #{$detail-width});
      transition-delay: 500ms;
    }
  }
}

.tree {
  display: flex;
  flex: 1 1 auto;
}

.detail {
  @include themed;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--color-bg-light);
  filter: drop-shadow(0 0 10px var(--color-shadow));
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;

  @media (min-width: $media-m) {
    width: $detail-width;
  }
}

.info {
  position: relative;
  flex: 1 1 auto;
}

.infoContent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: $spacing;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

.close {
  @include themed;

  margin: 0;
  padding: 13px $spacing 14px;
  -webkit-appearance: none;
  font-size: $font-size;
  background-color: transparent;
  border: 0;
  color: var(--color-text);
  cursor: pointer;
}

.detailloader {
  --color-loader-bg: var(--color-bg-light);

  @media (min-width: $media-m) {
    display: none;
  }
}
