@import 'shared/styles/abstract';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: $spacing;
  background-color: var(--color-result-bg);
  color: var(--color-result-text);
  text-decoration: none;
  border-radius: $radius-l;
  will-change: transform, opacity;
  filter: drop-shadow(2px 2px 1px var(--color-shadow));
  transition: all $transition-effect $transition-duration;
  outline: 0;
  text-align: center;
  overflow: hidden;

  &:hover,
  &:focus {
    --color-result-text: var(--color-result-text-hover);
    --color-result-bg: var(--color-result-bg-hover);
  }
}

.top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 auto;
  overflow: hidden;
}

.topline {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gender {
  flex: 0 0 auto;
  padding-right: $spacing-s;
  --color-icon: var(--color-result-text);
}

.name {
  @include ellipsis;
  @include heading;

  flex: 1 1 auto;
  margin: 0;
  padding: 0;
}

.info {
  margin: $spacing-s 0 0;
}

.birthplace,
.deathplace {
  display: inline-block;
}
